import React from 'react';
import './RadioButton.scss';

interface RadioButtonProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  label: string;
}

export function RadioButton({
  label,
  checked,
  onChange,
}: RadioButtonProps): JSX.Element {
  const defaultChecked = checked ? checked : false;
  const handleChange = (): void => {
    onChange(!checked);
  };

  return (
    <div className="radio-wrapper">
      <label>
        <input type="radio" checked={defaultChecked} onChange={handleChange} />
        <span>{label}</span>
      </label>
    </div>
  );
}
