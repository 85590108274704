import React from 'react';
import './Success.scss';
import { useTranslation } from 'react-i18next';

export function Success({
  successImage: successImage,
}: {
  successImage: string;
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="success">
      <img alt="Success" width="100" src={successImage} />
      <h2>{t('successTitle')}</h2>
      <p>{t('success')}</p>
    </div>
  );
}
