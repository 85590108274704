import React, { useEffect, useState } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { HomePage, GenericErrorPage, ResultPage } from './pages';
import { configurationService } from './services/configuration.service';
import { Config } from './models';
import { SubscriptionsData } from './models/subscriptions-data.interface';
import { apiService } from './services/api.service';
import { Loading } from './components/Loading/Loading';

enum AppState {
  Loading,
  Loaded,
  Failed,
}

export function App(): JSX.Element {
  const [state, setState] = useState(AppState.Loading);
  const [config, setConfig] = useState<Config>();
  const [subscriptionsData, setSubscriptionsData] = useState<
    SubscriptionsData[]
  >([]);

  useEffect(() => {
    const initConfigs = async (): Promise<void> => {
      try {
        await configurationService.loadConfig();
        setConfig(configurationService.getConfig());
        const subscriptions = await apiService.get<SubscriptionsData[]>(
          'subscriptions',
        );
        setSubscriptionsData(subscriptions);
        setState(AppState.Loaded);
      } catch (err) {
        setState(AppState.Failed);
      }
    };

    void initConfigs();
  }, []);

  switch (state) {
    case AppState.Loading:
      return <Loading />;
    case AppState.Loaded:
      return (
        <RouterProvider
          router={createBrowserRouter([
            {
              path: '/',
              element: config ? (
                <HomePage
                  config={config}
                  subscriptionsData={subscriptionsData}
                />
              ) : (
                <div></div>
              ),
            },
            {
              path: 'error',
              element: <GenericErrorPage />,
            },
            {
              path: 'result',
              element: config ? <ResultPage config={config} /> : <div></div>,
            },
          ])}
        />
      );
    case AppState.Failed:
      return <GenericErrorPage />;
  }
}
