import React from 'react';
import './ResultPage.scss';
import { Config } from '../../models';
import { Header, Footer, Success, Failure } from '../../blocks';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../../components/Button/Button';
import { t } from 'i18next';

export function ResultPage({ config }: { config: Config }): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const { success } = (location.state || {}) as { success: boolean };

  function onBackClick(): void {
    navigate('/');
  }
  return (
    <div className="result-page">
      <Header config={config} />
      <div className="content">
        {success ? (
          <Success successImage={config.uiConfig.images.successImage}></Success>
        ) : (
          <Failure failureImage={config.uiConfig.images.failureImage}></Failure>
        )}
        <div className="back-button">
          <Button onClick={onBackClick}>{t('backButton')}</Button>
        </div>
      </div>
      {!config.appConfig.hideFooter && <Footer />}
    </div>
  );
}
