import React, { useEffect, useRef, useState } from 'react';
import { useOutsideClick } from '../../hooks';
import './Dropdown.scss';

interface DropdownItem {
  id: string;
  name: string;
}

interface DropdownProps {
  id: string;
  title?: string;
  placeholder?: string;
  data: DropdownItem[];
  selectedId?: string;
  onSelect?: (id: string) => void;
  className?: string;
  icon?: JSX.Element;
}

export const Dropdown = ({
  id,
  title = 'Select',
  data,
  selectedId,
  onSelect,
  icon,
  className,
  placeholder,
}: DropdownProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<DropdownItem | undefined>(
    selectedId ? data?.find((item) => item.id === selectedId) : undefined,
  );
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleChange = (item: DropdownItem) => (): void => {
    setSelectedItem(item);
    onSelect && onSelect(item.id);
    setIsOpen(false);
  };

  useEffect(() => {
    if (selectedId && data) {
      const newSelectedItem = data.find((item) => item.id === selectedId);
      newSelectedItem && setSelectedItem(newSelectedItem);
    } else {
      setSelectedItem(undefined);
    }
  }, [selectedId, data]);

  useOutsideClick({
    ref: dropdownRef,
    handler: () => setIsOpen(false),
  });

  const triggerIsOpened = (): void => setIsOpen((opened) => !opened);

  return (
    <div ref={dropdownRef} className={`dropdown ${className}`}>
      {icon && (
        <div className="dropdown-icon" onClick={triggerIsOpened}>
          {icon}
        </div>
      )}
      <button
        id={id}
        aria-label="Toggle dropdown"
        aria-haspopup="true"
        aria-expanded={isOpen}
        type="button"
        onClick={triggerIsOpened}
        className="dropdown-trigger"
      >
        <span>{title || selectedItem?.name || placeholder}</span>
        <i
          className={`dropdown-trigger-icon ${
            isOpen ? 'dropdown-trigger-icon__opened' : ''
          }`}
        ></i>
      </button>
      {isOpen && (
        <div aria-label="Dropdown menu" className="dropdown-menu-wrapper">
          <ul
            role="menu"
            aria-labelledby={id}
            aria-orientation="vertical"
            className="dropdown-menu"
          >
            {data?.map((item) => (
              <li
                key={item.id}
                onClick={handleChange(item)}
                className={`dropdown-menu-item ${
                  selectedItem?.id === item.id
                    ? 'dropdown-menu-item__selected'
                    : ''
                }`}
              >
                <span>{item.name}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
