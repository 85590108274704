import { SUBSCRIPTIONS_DATA_MOCK } from '../mocks/api/subscriptions-data.mock';

export class ApiService {
  payload = '';
  baseUrl = '';

  constructor() {
    const urlParams = new URLSearchParams(window.location.search);
    this.payload = encodeURIComponent(urlParams.get('payload') as string) || '';
  }

  setBaseUrl(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  async get<T>(path: string): Promise<T> {
    if (process.env.REACT_APP_USE_MOCKED_API === 'true') {
      return new Promise<T>((resolve) => {
        setTimeout(() => {
          resolve(SUBSCRIPTIONS_DATA_MOCK as T);
        }, 3000);
      });
    }

    const response = await fetch(
      `${this.baseUrl}/${path}?payload=${this.payload}`,
    );
    if (response.status >= 300) {
      // TODO throw error from response if it's presented or general error
    }
    return (await response.json()) as T;
  }

  async post<T, P>(path: string, body: P): Promise<T> {
    if (process.env.REACT_APP_USE_MOCKED_API === 'true') {
      const mockResponse = {
        status: 'success',
        data: body,
      };

      return new Promise<T>((resolve) => {
        setTimeout(() => {
          resolve(mockResponse as T);
        }, 3000);
      });
    }

    const response = await fetch(
      `${this.baseUrl}/${path}?payload=${this.payload}`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      },
    );
    if (response.status >= 300) {
      // TODO throw error from response if it's presented or general error
    }
    return (await response.json()) as T;
  }
}

export const apiService = new ApiService();
