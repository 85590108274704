import React from 'react';
import './Footer.scss';
import { useTranslation } from 'react-i18next';

export function Footer(): JSX.Element {
  const { t } = useTranslation();

  return (
    <div
      className="footer"
      dangerouslySetInnerHTML={{ __html: t('footerText') }}
    ></div>
  );
}
