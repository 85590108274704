import React from 'react';
import './Loading.scss';

export function Loading(): JSX.Element {
  return (
    <div className="wrapper">
      <span className="loader"></span>
    </div>
  );
}
