import React from 'react';
import './GenericErrorPage.scss';

export function GenericErrorPage(): JSX.Element {
  const handleRefresh = (): void => {
    window.location.href = '/';
  };

  return (
    <div className="generic-error-page">
      <h1>Oops!</h1>
      <img src="/assets/images/error-icon.png" alt="Error Image" />
      <p>
        Unable to load page content. Please make sure cookies are not blocked
        and refresh the page. If the issues persist, please try again later.
      </p>
      <button onClick={handleRefresh}>Reload Page</button>
    </div>
  );
}
