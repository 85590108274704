import React from 'react';
import './HomePage.scss';
import { Banner } from '../../components';
import { Config } from '../../models';
import { Header, Footer, Intro, Subscriptions } from '../../blocks';
import { SubscriptionsData } from '../../models/subscriptions-data.interface';

export function HomePage({
  config,
  subscriptionsData,
}: {
  config: Config;
  subscriptionsData: SubscriptionsData[];
}): JSX.Element {
  return (
    <div className="home-page">
      <Header config={config} />
      <Banner
        className="banner-top"
        bannerImage={config.uiConfig.images.banner}
      />
      <div className="home-layout">
        <div className="home-content">
          <Intro></Intro>
          <Subscriptions
            subscriptionsData={subscriptionsData}
            config={config}
          ></Subscriptions>
        </div>
        <div>
          <Banner
            className="banner-right"
            bannerImage={
              config.uiConfig.images.bannerSquare ||
              config.uiConfig.images.banner
            }
          />
        </div>
      </div>
      {!config.appConfig.hideFooter && <Footer />}
    </div>
  );
}
