import React from 'react';
import './UpdateAll.scss';
import { RadioButton } from '../RadioButton/RadioButton';
import { Button } from '../Button/Button';
import { Config } from '../../models';

interface UpdateAllProps {
  label: string;
  config: Config;
  onChange: () => void;
  checked: boolean;
  hasTitle?: boolean;
  title?: string;
}

export function UpdateAll({
  label,
  config,
  onChange,
  checked,
  hasTitle,
  title,
}: UpdateAllProps): JSX.Element {
  return (
    <div className="update-all">
      {hasTitle && <div className="update-all-title">{title}</div>}
      {config.appConfig.subscribeAndUnsubscribeAllButtonType === 'radio' ? (
        <RadioButton checked={checked} onChange={onChange} label={label} />
      ) : (
        <Button onClick={onChange}>{label}</Button>
      )}
    </div>
  );
}
