import React from 'react';
import './Intro.scss';
import { useTranslation } from 'react-i18next';

export function Intro(): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="intro home-content-item">
      <h2 className="intro-header">
        {t('header', { email: 'Ahmed@test.com' })}
      </h2>
      <p
        dangerouslySetInnerHTML={{
          __html: t('content', { email: 'Ahmed@test.com' }),
        }}
        className="intro-content"
      ></p>
    </div>
  );
}
