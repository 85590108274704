import React, { useState, FormEvent, useMemo } from 'react';
import './Subscriptions.scss';
import { Checkbox } from '../../components/Checkbox/Checkbox';
import { SubscriptionsData } from '../../models/subscriptions-data.interface';
import { Button } from '../../components/Button/Button';
import { useTranslation } from 'react-i18next';
import { Config } from '../../models';
import { UpdateAll } from '../../components/UpdateAll/UpdateAll';
import { apiService } from '../../services/api.service';
import { useNavigate } from 'react-router-dom';

interface FormData {
  [key: string]: boolean;
}

interface SubscriptionsProps {
  subscriptionsData: SubscriptionsData[];
  config: Config;
}

export function Subscriptions({
  subscriptionsData,
  config,
}: SubscriptionsProps): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const initialFormData: FormData = subscriptionsData.reduce(
    (acc, { key, checked }) => ({
      ...acc,
      [key]: checked,
    }),
    {},
  );

  const [formData, setFormData] = useState(initialFormData);
  const [showLoader, setShowLoader] = useState(false);

  const isSubscribedToAll = useMemo(
    () => Object.values(formData).every((value) => value),
    [formData],
  );
  const isUnSubscribedFromAll = useMemo(
    () => Object.values(formData).every((value) => !value),
    [formData],
  );
  const isSubmitDisabled = useMemo(
    () =>
      showLoader ||
      subscriptionsData.every(
        ({ key }) => formData[key] === initialFormData[key],
      ),
    [showLoader, formData],
  );

  const handleCheckboxChange =
    (key: string) =>
    (value: boolean): void => {
      setFormData((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    };

  const updateAllCheckboxes = (value: boolean): void => {
    setFormData((prevState) =>
      Object.keys(prevState).reduce((acc: FormData, key) => {
        acc[key] = value;
        return acc;
      }, {}),
    );
  };

  const handleSubmit = async (): Promise<void> => {
    setShowLoader(true);
    try {
      await apiService.post('subscriptions', formData);
      // Handle post-submission success actions here
      navigate('/result', { state: { success: true } });
    } catch (error) {
      // Handle error actions here
      navigate('/result', { state: { success: false } });
    } finally {
      setShowLoader(false);
    }
  };

  function onSubmit(event: FormEvent): void {
    event.preventDefault();
    void handleSubmit();
  }

  const subscribeToAll = (): void => updateAllCheckboxes(true);
  const unSubscribeFormAll = (): void => updateAllCheckboxes(false);

  return (
    <form onSubmit={onSubmit}>
      <div className="home-content-item">
        {config.appConfig.hasSubscribeAllButton && (
          <UpdateAll
            config={config}
            label={t('subscribeToAll')}
            onChange={subscribeToAll}
            checked={isSubscribedToAll}
            hasTitle={config.appConfig.hasSubscribeAllText}
            title={t('subscribeToAllTitle')}
          />
        )}
      </div>

      <div className="subscription-options home-content-item">
        {config.appConfig.hasSubscriptionOptionsTitle && (
          <p className="subscription-options-title">
            {t('subscriptionOptionsTitle')}
          </p>
        )}
        <ul className="subscription-options-list">
          {subscriptionsData.map(({ key, label }) => {
            return (
              <li key={key}>
                <Checkbox
                  checked={formData[key]}
                  onChange={handleCheckboxChange(key)}
                  label={label}
                />
              </li>
            );
          })}
        </ul>
      </div>

      <div className="home-content-item">
        {config.appConfig.hasUnsubscribeAllButton && (
          <UpdateAll
            config={config}
            label={t('unsubscribeFromAll')}
            onChange={unSubscribeFormAll}
            checked={isUnSubscribedFromAll}
            hasTitle={config.appConfig.hasUnsubscribeAllText}
            title={t('unsubscribeFromAllTitle')}
          />
        )}
      </div>

      <div className="submit-button home-content-item">
        <Button type="submit" loading={showLoader} disabled={isSubmitDisabled}>
          {t('updateButton')}
        </Button>
      </div>
    </form>
  );
}
