import React from 'react';
import './Header.scss';
import { Config } from '../../models';
import { Logo } from '../../components';
import { LanguageSelector } from '../../components/LanguageSelector/LanguageSelector';

export function Header({ config }: { config: Config }): JSX.Element {
  return (
    <div className="header">
      <a href={config.appConfig.websiteUrl} className="header-logo">
        <Logo logoImage={config.uiConfig.images.logo} />
      </a>

      {config.appConfig.languageSelectorEnabled && <LanguageSelector />}
    </div>
  );
}
