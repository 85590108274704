import React from 'react';
import './Banner.scss';

export function Banner({
  bannerImage,
  className,
}: {
  bannerImage: string;
  className: string;
}): JSX.Element {
  return <img className={`${className} banner-image`} src={bannerImage} />;
}
