import { Config, UIConfig } from '../models';
import { initFonts, initThemeStyles } from '../utils';
import { apiService } from './api.service';
import { translationsService } from './translations.service';
import { ConfigScheme } from '../json-schemes';

export class ConfigurationService {
  config!: Config;

  getConfig(): Config {
    return this.config;
  }

  // This method must be called in App component
  async loadConfig(): Promise<void> {
    const config: Config =
      process.env.REACT_APP_USE_LOCAL_CONFIG === 'true'
        ? await import(
            `../mocks/configs/${process.env.REACT_APP_CUSTOMER}.json`
          )
        : await this.fetchConfigFile();

    this.config = config;
    if (!this.isConfigValid(config)) {
      throw Error('Config is invalid');
    }

    await translationsService.initTranslations(config.languages);
    initThemeStyles(this.config.uiConfig);
    initFonts(this.config.uiConfig);
    this.initFavicon(this.config.uiConfig);
    apiService.setBaseUrl(this.config.appConfig.apiBaseUrl);
  }

  private async fetchConfigFile(): Promise<Config> {
    // TODO implement fetching
    return Promise.resolve({} as Config);
  }

  private initFavicon(config: UIConfig): void {
    const link: HTMLLinkElement = document.createElement('link');
    link.setAttribute('href', config.images.favicon);
    link.setAttribute('rel', 'icon');
    document.head.appendChild(link);
  }

  private isConfigValid(config: Config): boolean {
    const result = ConfigScheme.safeParse(config);

    if (!result.success) {
      console.error('Config validation errors:', result.error?.issues);
      const issues: Record<string, { Error: string }> = {};
      result.error?.issues.forEach((err) => {
        issues[err.path.join('.')] = { Error: err.message };
      });
      console.table(issues, ['Error']);
    }

    return result.success;
  }
}

export const configurationService = new ConfigurationService();
