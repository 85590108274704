import React from 'react';
import './Failure.scss';
import { useTranslation } from 'react-i18next';

export function Failure({
  failureImage,
}: {
  failureImage: string;
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="failure">
      <img width="100" alt="Error" src={failureImage} />
      <h2>{t('errorTitle')}</h2>
      <p>{t('error')}</p>
    </div>
  );
}
