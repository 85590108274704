import React, { useState, useMemo } from 'react';
import './LanguageSelector.scss';
import { translationsService } from '../../services/translations.service';
import { GlobeIcon } from '../GlobeIcon/GlobeIcon';
import { LANGUAGES_MAPPING } from './languages-mapping.const';
import { Dropdown } from '../Dropdown/Dropdown';

export function LanguageSelector(): JSX.Element {
  const [currentLang, setCurrentLang] = useState(
    translationsService.getCurrentLanguage(),
  );
  const languagesList = useMemo(
    () =>
      translationsService.getAvailableLanguages().map((lang) => ({
        id: lang,
        name: LANGUAGES_MAPPING[lang],
      })),
    [],
  );

  const changeLanguage = (lang: string): void => {
    setCurrentLang(lang);
    void translationsService.changeLanguage(lang);
  };

  if (languagesList.length <= 1) {
    return <></>;
  }

  return (
    <div className="language-selector">
      <Dropdown
        id="language-selector"
        data={languagesList}
        onSelect={changeLanguage}
        selectedId={currentLang}
        title={currentLang?.toUpperCase()}
        icon={<GlobeIcon className="language-icon" />}
      />
    </div>
  );
}
