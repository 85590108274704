import React from 'react';
import './Checkbox.scss';

interface CheckboxProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  label: string;
}

export function Checkbox({
  label,
  checked,
  onChange,
}: CheckboxProps): JSX.Element {
  const defaultChecked = checked ? checked : false;
  const handleChange = (): void => {
    onChange(!checked);
  };

  return (
    <div className="checkbox-wrapper">
      <label>
        <input
          type="checkbox"
          checked={defaultChecked}
          onChange={handleChange}
        />
        <span>{label}</span>
      </label>
    </div>
  );
}
